import React from 'react';
import { useTranslation } from "react-i18next";
export const BackOffice = () => {
    const { t, i18n } = useTranslation("global");
    return (
        <div className="container-fluid p-0 text-center">
        <div className="col-12 pb-5 h1 textTurque">{t('servicios.servicios.backOffice.text1')}</div>

        <div className="row g-0 text-start">
            <div className="col-12 col-md-6 col-xl-4 p-2">
                <div className="col-12 rounded shadow p-4">
                    <div className="col-12 py-3 text-center display-1 textTurque">
                        <i className="fas fa-server"></i>
                    </div>
                    {t('servicios.servicios.backOffice.text2')}
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 p-2">
                <div className="col-12 rounded shadow p-4">
                    <div className="col-12 py-3 text-center display-1 textTurque">
                        <i className="far fa-edit"></i>
                    </div>
                    {t('servicios.servicios.backOffice.text3')}
                </div>
            </div>
            <div className="col-12 col-xl-4 p-2">
                <div className="col-12 rounded shadow p-4">
                    <div className="col-12 py-3 text-center display-1 textTurque">
                        <i className="fas fa-chart-bar"></i>
                    </div>
                    {t('servicios.servicios.backOffice.text4')}
                </div>
            </div>
        </div>
    </div>
    )

}