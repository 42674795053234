import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import './assets/css/master.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider} from "react-i18next";
import i18next from "i18next";

import global_es from "./translations/ESP/global.json"
import global_en from "./translations/ENG/global.json"

i18next.init({
  interpolation:{escapeValue:false},
  lng:"es",
  resources:{
    es:{
      global: global_es
    },
    en:{
      global: global_en
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
