import { HashLink } from 'react-router-hash-link';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { send } from 'emailjs-com';
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
/*componentes*/

/*imagenes */
import grim from '../assets/img/partners/LOGO-GRIMLOCK.jpg';
import center from '../assets/img/partners/LOGOCENTER.jpg'

export const Packages = () => {
    const { t, i18n } = useTranslation("global");
    const [toSend, setToSend] = useState({
        fullname: '',
        email: '',
        message: '',
        phone: '',
        type: '',
    });

    const contact = (e) => {
        e.preventDefault();
        send(
            'service_uvwm6lw',
            'template_0c8lkik',
            toSend,
            'gLZmbPr8_P0yjdklu'
        )
            .then((response) => {
                alert("The form was sent correctly");
                console.log('SUCCESS!', response.status, response.text);
            })
            .catch((err) => {
                alert("The form was not submitted correctly");
                console.log('FAILED...', err);
            });
        e.target.reset()
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    const onChangeValue = (e) => {
        const valor = e.target.value
        toSend.type = valor;
        console.log(toSend.type);
    }
    const captcha = useRef(null);
    const onChange = () => {
        document.getElementById('senBtnBu').disabled = false;
    }
    return (
        <div className="container-fluid p-0 bgBlack">
            <Helmet>
                <title>Packages</title>
            </Helmet>
            <div className="bgLinearBlue text-white text-center minH50vh d-flex justify-content-center align-items-center text-center" id="packages-top">
                <div className="p-2">
                    <div className="display-3">{t('paquetes.title')}</div>
                    <div className="col-12 col-lg-6 offset-lg-3 mt-3 text-start">
                        <p>{t('paquetes.slider')} </p>
                    </div>
                </div>
            </div>
            <div className="container text-center py-5">
                <div className="col-12 ">
                    <h2>Paquetes</h2>
                </div>
                <div className="row g-0">
                    <div className="col-12 col-lg-4 p-lg-2">
                        <div className="col-12 rounded shadow-lg overflow-hidden g-0" data-aos="flip-up">
                            <div className="col-12 bg-Parner-Title-Basic text-white">Basico</div>
                            <div className="list-paket-parners">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 1
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 2
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 3
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 4
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 5
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 pb-2" data-aos="flip-down">
                                <HashLink smooth className="btn btnBlue" to="/contact#contact-usTop">{t('buttons.cotisar')}</HashLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-lg-2">
                        <div className="col-12 rounded shadow-lg overflow-hidden g-0" data-aos="flip-up">
                            <div className="col-12 bg-Parner-Title-Standart text-white">Standart</div>
                            <div className="list-paket-parners">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 1
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 2
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 3
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 4
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 5
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 6
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 7
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 pb-2" data-aos="flip-down">
                                <HashLink smooth className="btn btnBlue" to="/contact#contact-usTop">{t('buttons.cotisar')}</HashLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-lg-2">
                        <div className="col-12 rounded shadow-lg overflow-hidden g-0" data-aos="flip-up">
                            <div className="col-12 bg-Parner-Title-Premium">Premium</div>
                            <div className="list-paket-parners">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 1
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 2
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 3
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 4
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 5
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 6
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 7
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 8
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 9
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row g-0">
                                            <div className="col-2 text-center">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="col-10">
                                                item 10
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 pb-2" data-aos="flip-down">
                                <HashLink smooth className="btn btnBlue" to="/contact#contact-usTop">{t('buttons.cotisar')}</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container rounded shadow my-3" data-aos="fade-left">

                <div className="col-12 p-3 d-flex justify-content-center align-items-center text-center" >
                    <div className="">

                    </div>
                </div>
                <div className="col-12 p-0">
                    <form onSubmit={contact} className="text-uppercase">
                        <div className="row g-0">
                            <div className="col-12 col-lg-6">
                                <div className="row g-0">
                                    <div className="col-6 p-2">
                                        <input className="col-12 rounded shadow" name="fullname" id="fullname" type="text" placeholder={t('contact.form.text1')} aria-label="fullname" value={toSend.fullname} onChange={handleChange} required />
                                    </div>
                                    <div className="col-6 p-2">
                                        <input className="col-12 rounded shadow" name="phone" id="phone" type="tel" placeholder={t('contact.form.text2')} aria-label="phone" value={toSend.phone} onChange={handleChange} required />
                                    </div>
                                    <div className="col-12 p-2">
                                        <input className="col-12 rounded shadow" name="email" id="emailAddress" type="mail" placeholder={t('contact.form.text3')} aria-label="emailAddress" value={toSend.email} onChange={handleChange} required />
                                    </div>
                                    <div className="col-12 p-2">
                                        <textarea className="col-12 rounded shadow" id="message" name="message" rows="5" placeholder={t('contact.form.text4')} value={toSend.message} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 ps-5">
                                <div className="col-12 fw-bold text-center">
                                    Dinos que ocupas y te lo cotizamos
                                </div>
                                <div className="fw-bold text-center">Que paquete te interesa</div>
                                <div className="col-12 p-1" onChange={onChangeValue}>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="radio" name="promotions" id="promotions1" value="Basico" required />
                                        <label className="form-check-label" htmlFor="promotions1">
                                            Basico
                                        </label>
                                    </div>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="radio" name="promotions" id="promotions2" value="Standart" />
                                        <label className="form-check-label" htmlFor="promotions2">
                                            Standart
                                        </label>
                                    </div>
                                    <div className="form-check py-1">
                                        <input className="form-check-input" type="radio" name="promotions" id="promotions3" value="Premium" />
                                        <label className="form-check-label" htmlFor="promotions3">
                                            Premium
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center py-5">
                            <div className="col-12 offset-xl-4 col-xl-4 p-2">
                                <ReCAPTCHA
                                    sitekey="6LcboWQpAAAAAPRGRHk2vgtjdF3zSY5Bcdq3f-HS"
                                    onChange={onChange}
                                />
                            </div>
                            <button id="senBtnBu" className="btnBlue btn" disabled type="submit">{t('buttons.submit')} <i className="bi bi-arrow-right"></i></button>
                        </div>
                    </form>
                </div>

            </div>
            <div className="container py-5 text-center">
                <div className="col-12 pb-5">
                    <h2>Partners</h2>
                </div>
                <div id="partners-caousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="d-flex g-0">
                                <div className="flex-fill"><img className="img-thumbnail" src={grim}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={center}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={grim}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={center}></img></div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="d-flex g-0">
                                <div className="flex-fill"><img className="img-thumbnail" src={grim}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={center}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={grim}></img></div>
                                <div className="flex-fill"><img className="img-thumbnail" src={center}></img></div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#partners-caousel" data-bs-slide="prev">
                        <i className="fas fa-chevron-circle-left"></i>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#partners-caousel" data-bs-slide="next">
                        <i className="fas fa-chevron-circle-right"></i>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
