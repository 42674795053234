import { Helmet } from "react-helmet";
import { HashLink } from 'react-router-hash-link';
import React, { useState } from 'react';
import "../../node_modules/aos/dist/aos.css";
import { useTranslation } from "react-i18next";
/*componentes*/
import { ServicioCliente } from '../servicios/servicioCliente';
import { Ventas } from '../servicios/ventas';
import { BackOffice } from '../servicios/backOffice';
import { Billing } from '../servicios/billing';
import { Collections } from '../servicios/collections';
import { Payroll } from '../servicios/payroll';
import { Accounting } from '../servicios/accounting';
import { Legal } from '../servicios/legal';
import { Hiring } from '../servicios/hiring';
import { Podcast } from '../servicios/podcast';
import { Crm } from '../servicios/crm';
import { CallToAction } from '../components/callToAction';
/* img */

export const Servicios = () => {

    const { t, i18n } = useTranslation("global");

    return (
        <div className="container-fluid p-0" id="serviciosTop">
            <div className="container-fluid bgBlue text-white py-5" id="services">
                <div className="container">
                    <div className="col-12 display-5 text-center" data-aos="zoom-in">{t('servicios.span1.text1')}</div>
                    <div className="col-12" data-aos="zoom-in">{t('servicios.span1.text1-1')}</div>
                    <div className="col-12" data-aos="zoom-in">{t('servicios.span1.text2')}</div>
                </div>
            </div>
            <div className="container-fluid text-center mb-5 p-0" id="selectServices">
                <div className="row g-0">
                    <div className="col-12 col-lg-2 bgBlueKing" data-aos="fade-right">
                        <div className="col-12">
                            <div className="col-12 selectServiceTitle">{t('servicios.span1.text3')}</div>
                            <ul className="nav nav-pills mb-3 text-center" id="pills-tab-content1" role="tablist">
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService active" id="pills-servicio-tab" data-bs-toggle="pill" data-bs-target="#pills-servicio" type="button" role="tab" aria-controls="pills-servicio" aria-selected="true">{t('servicios.span2.text1')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Sales-tab" data-bs-toggle="pill" data-bs-target="#pills-Sales" type="button" role="tab" aria-controls="pills-Sales" aria-selected="false">{t('servicios.span2.text2')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Billing-tab" data-bs-toggle="pill" data-bs-target="#pills-Billing" type="button" role="tab" aria-controls="pills-Billing" aria-selected="false">{t('servicios.span2.text3')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Collections-tab" data-bs-toggle="pill" data-bs-target="#pills-Collections" type="button" role="tab" aria-controls="pills-Collections" aria-selected="false">{t('servicios.span2.text5')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-backOffice-tab" data-bs-toggle="pill" data-bs-target="#pills-backOffice" type="button" role="tab" aria-controls="pills-backOffice" aria-selected="false">{t('servicios.span2.text4')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <div className="col-12 selectServiceTitle">{t('servicios.span1.text4')}</div>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService " id="pills-Payroll-tab" data-bs-toggle="pill" data-bs-target="#pills-Payroll" type="button" role="tab" aria-controls="pills-Payroll" aria-selected="true">{t('servicios.span2.text6')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Accounting-tab" data-bs-toggle="pill" data-bs-target="#pills-Accounting" type="button" role="tab" aria-controls="pills-Accounting" aria-selected="false">{t('servicios.span2.text7')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Legal-tab" data-bs-toggle="pill" data-bs-target="#pills-Legal" type="button" role="tab" aria-controls="pills-Legal" aria-selected="false">{t('servicios.span2.text8')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Hiring-tab" data-bs-toggle="pill" data-bs-target="#pills-Hiring" type="button" role="tab" aria-controls="pills-Hiring" aria-selected="false">{t('servicios.span2.text9')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                {/* <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-Podcast-tab" data-bs-toggle="pill" data-bs-target="#pills-Podcast" type="button" role="tab" aria-controls="pills-Podcast" aria-selected="false">{t('servicios.span2.text10')} <i className="fas fa-caret-right"></i></div>
                                </li>
                                <li className="nav-item col-12" role="presentation">
                                    <div className="selectService" id="pills-CRM-tab" data-bs-toggle="pill" data-bs-target="#pills-CRM" type="button" role="tab" aria-controls="pills-CRM" aria-selected="false">{t('servicios.span2.text11')} <i className="fas fa-caret-right"></i></div>
                                </li> */}
                            </ul>
                        </div>

                        <div className="col-12">
                            <ul className="nav nav-pills mb-3" id="pills-tab-content2" role="tablist">

                            </ul>

                        </div>
                    </div>
                    <div className="col-12 col-lg-10 pt-5" data-aos="fade-up">
                        <div className="tab-content">
                            <div className="tab-pane fade show active CallCenter" id="CallCenter2" role="tabpanel" aria-labelledby="CallCenter-tab">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-servicio" role="tabpanel" aria-labelledby="pills-servicio-tab"><ServicioCliente />
                                    </div>
                                    <div className="tab-pane fade" id="pills-Sales" role="tabpanel" aria-labelledby="pills-Sales-tab"><Ventas /></div>
                                    <div className="tab-pane fade" id="pills-Billing" role="tabpanel" aria-labelledby="pills-Billing-tab"><Billing /></div>
                                    <div className="tab-pane fade" id="pills-Collections" role="tabpanel" aria-labelledby="pills-Collections-tab"><Collections /></div>
                                    <div className="tab-pane fade" id="pills-backOffice" role="tabpanel" aria-labelledby="pills-backOffice-tab"><BackOffice /></div>
                                    <div className="tab-pane fade" id="pills-Payroll" role="tabpanel" aria-labelledby="pills-Payroll-tab"><Payroll /></div>
                                    <div className="tab-pane fade" id="pills-Accounting" role="tabpanel" aria-labelledby="pills-Accounting-tab"><Accounting /></div>
                                    <div className="tab-pane fade" id="pills-Legal" role="tabpanel" aria-labelledby="pills-Legal-tab"><Legal /></div>
                                    <div className="tab-pane fade" id="pills-Hiring" role="tabpanel" aria-labelledby="pills-Hiring-tab"><Hiring /></div>
                                    {/* <div className="tab-pane fade" id="pills-Podcast" role="tabpanel" aria-labelledby="pills-Podcast-tab"><Podcast /></div>
                                    <div className="tab-pane fade" id="pills-CRM" role="tabpanel" aria-labelledby="pills-CRM-tab"><Crm /></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div />
                    <CallToAction />
                </div>
            </div>
        </div>
    );
};
