import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
/*componentes*/
import { CallToAction } from "../components/callToAction";
/* img */


export const AboutUS = () => {
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, []);
    return (
        <div className="container-fluid p-0" id="aboutTop">
            <div className="slider sliderAbout d-flex justify-content-center align-items-center">
                <div className="p-3 col-12 bgBlueTrans" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <h2 className="text-center">{t('about.slider.slogan')}</h2>
                        <h4 className="text-end fst-italic">-{t('about.slider.cita')}</h4>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="col-12" data-aos="fade-up-right">
                    <h2 className="display-5 text-center textTurque pb-5">{t('about.slider.text1')}</h2>
                    <p className="text-start">{t('about.slider.text2')}</p>
                    <p className="text-start">{t('about.span.text5')}</p>
                </div>
                <div data-aos="fade-up-left">
                    <div className="col-12 text-center pb-5 display-5 textTurque">
                        {t('about.span.text6')}
                    </div>
                    <div className="col-12">
                        <p className="text-start ">{t('about.span.text7')}</p>
                    </div>
                    <div className="col-12">
                        <p className="text-start">{t('about.span.text8')}</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid aboutSpan d-flex justify-content-center align-items-center py-5">
                <div className="row g-0  text-white">
                    <div className="col-6"></div>
                    <div className="col-12 col-lg-6">
                        <div className="col-12" data-aos="fade-left">
                            <div className="display-5">{t('about.span.text1')}</div>
                            <div className="">
                                {t('about.span.text2')}<br />
                                {t('about.span.text3')}<br />
                                {t('about.span.text4')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
        </div>
    );
};
