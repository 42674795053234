import React from 'react';
import { useTranslation } from "react-i18next";
export const Billing = () => {
    const { t, i18n } = useTranslation("global");
    return (
        <div className="container-fluid p-0 text-center">
            <div className="col-12 pb-5 h1 textTurque">{t('servicios.servicios.Billing.text1')}</div>
            <div className="row g-0 text-start">
                <div className="col-12 col-md-6 col-xl-4 p-2">
                    <div className="col-12 rounded shadow p-4">
                        <div className="col-12 py-3 text-center display-1 textTurque">
                            <i className="fas fa-phone-volume"></i>
                        </div>
                        {t('servicios.servicios.Billing.text3')}
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-2">
                    <div className="col-12 rounded shadow p-4">
                        <div className="col-12 py-3 text-center display-1 textTurque">
                            <i className="fas fa-hand-holding-heart"></i>
                        </div>
                        {t('servicios.servicios.Billing.text4')}
                    </div>
                </div>
                <div className="col-12 col-xl-4 p-2">
                    <div className="col-12 rounded shadow p-4">
                        <div className="col-12 py-3 text-center display-1 textTurque">
                            <i className="fas fa-chart-line"></i>
                        </div>
                        {t('servicios.servicios.Billing.text5')}
                    </div>
                </div>
            </div>
        </div>
    )

}