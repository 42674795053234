import { Helmet } from "react-helmet";
import { send } from 'emailjs-com';
import React, { useState } from 'react';
import "../../node_modules/aos/dist/aos.css";
import { useTranslation } from "react-i18next";
/*componentes*/
import { CallToAction } from '../components/callToAction'
/* img */

export const Faqs = () => {
    const { t, i18n } = useTranslation("global");

    return (
        <div className="container-fluid p-0" id="faqsTop">
            <div className="slider sliderFaqs d-flex justify-content-center align-items-center">
                <div className="row g-0">
                    <div className="col-12 text-center d-flex justify-content-center align-items-center">
                        <div className="bgBlueTrans p-3" data-aos="zoom-out-up">
                            <div className="col-12 col-lg-6 offset-lg-3 fs-3">
                                <p>{t('faqs.slider.text2')}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-12 text-center display-5 py-5" data-aos="zoom-in">{t('faqs.span.text1')}</div>
                <div className="col-12 text-center" data-aos="zoom-in">
                    {t('faqs.span.text2')}
                </div>
                <div className="col-12" data-aos="zoom-in">
                                <ul className="list-group list-group-horizontal-lg">
                                    <li className="list-group-item flex-fill">{t('faqs.span.text3')}</li>
                                    <li className="list-group-item flex-fill">{t('faqs.span.text4')}</li>
                                    <li className="list-group-item flex-fill">{t('faqs.span.text5')}</li>
                                    <li className="list-group-item flex-fill">{t('faqs.span.text6')}</li>
                                    <li className="list-group-item flex-fill">{t('faqs.span.text7')}</li>
                                    <li className="list-group-item flex-fill">{t('faqs.span.text8')}</li>
                                </ul>
                            </div>
                <div className="col-12 py-5">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button text-center col-12" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {t('faqs.faq1.title')}
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq1.text1')} <br /> {t('faqs.faq1.text2')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {t('faqs.faq2.title')}
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text1')}</strong>: {t('faqs.faq2.text2')}
                                    </div>
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text3')}</strong>: {t('faqs.faq2.text4')}
                                    </div>
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text5')}</strong>: {t('faqs.faq2.text6')}
                                    </div>
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text7')}</strong>: {t('faqs.faq2.text8')}
                                    </div>
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text9')}</strong>: {t('faqs.faq2.text10')}
                                    </div>
                                    <div className="col-12">
                                        <strong>{t('faqs.faq2.text11')}</strong>: {t('faqs.faq2.text12')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq3.title')}
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq3.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq4.title')}
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq4.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq5.title')}
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq5.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSept" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq6.title')}
                                </button>
                            </h2>
                            <div id="collapseSept" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq6.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq7.title')}
                                </button>
                            </h2>
                            <div id="collapseEigth" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq7.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq8.title')}
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq8.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThen" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq9.title')}
                                </button>
                            </h2>
                            <div id="collapseThen" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq9.text1')}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos="flip-up">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseThree">
                                    {t('faqs.faq10.title')}
                                </button>
                            </h2>
                            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {t('faqs.faq10.text1')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
        </div>
    );
};
