import React from 'react';
import { useTranslation } from "react-i18next";
export const Legal = () => {
    const { t, i18n } = useTranslation("global");
    return (
        <div className="container-fluid p-0 text-center">
            <div className="col-12 pb-5 h1 textTurque">{t('servicios.servicios.legal.text1')}</div>
            <div className="container">
                {t('servicios.servicios.legal.text2')}
            </div>

            <div className="row g-0 text-start">
                <div className="col-12 col-lg-6 p-2">
                    <div className="col-12 rounded shadow p-4">
                        <div className="col-12 py-3 text-center display-1 textTurque">
                            <i className="far fa-file-alt"></i>
                        </div>
                        {t('servicios.servicios.legal.text3')}
                    </div>
                </div>
                <div className="col-12 col-lg-6 p-2">
                    <div className="col-12 rounded shadow p-4">
                        <div className="col-12 py-3 text-center display-1 textTurque">
                            <i className="fas fa-balance-scale"></i>
                        </div>
                        {t('servicios.servicios.legal.text4')}
                    </div>
                </div>
            </div>
        </div>
    )

}