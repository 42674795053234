import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import { send } from 'emailjs-com';
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../store/appContext";
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
/*componentes*/
import Post from "../components/post";
/* img */


export const Home = () => {
	const { t, i18n } = useTranslation("global");
	const { store, actions } = useContext(Context);
	useEffect(() => {
		AOS.init({ duration: 1500 });
	}, []);

	const [toSend, setToSend] = useState({
		fullname: '',
		email: '',
		message: '',
		phone: '',
	});

	const contact = (e) => {
		e.preventDefault();
		send(
			'service_5ihc1yh',
			'template_wxz9lki',
			toSend,
			'user_ueDJqGkpi3yp8nodYgnsk'
		)
			.then((response) => {
				alert("The form was sent correctly");
				console.log('SUCCESS!', response.status, response.text);
			})
			.catch((err) => {
				alert("The form was not submitted correctly");
				console.log('FAILED...', err);
			});
		e.target.reset()
	};

	const handleChange = (e) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};

	return (
		<div className="container-fluid p-0 overflow-hidden bgLightGrey" id="homeTop">
			<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
				<div className="carousel-inner">
					<div className="carousel-item active">
						<div className="slider sliderHome d-flex justify-content-center align-items-center text-center">
							<div className="slider manoSlider"></div>
							<div className="p-3" data-aos="fade-up" data-aos-duration="2000">
								<h2 className="display-5">{t('home.slider.text1')}</h2>
								<div className="col-12 col-lg-6 offset-lg-3">
									<p>{t('home.slider.text2')}<br /> {t('home.slider.text3')}</p>
								</div>
								<div className="col-12 text-center">
									<HashLink to="/services" className="btn btn-outline-light col-2">{t('buttons.mas')}</HashLink>
								</div>
							</div>
						</div>
					</div>
					<div className="carousel-item">
						<div className="slider sliderAmerica d-flex justify-content-center align-items-center text-center">
							<div className="p-3 col-lg-6 offset-lg-3" data-aos="fade-up" data-aos-duration="2000">
								<div className="col-12 fs-2 pb-5">
									{t('home.span1.text11')}
								</div>
								<div className="col-12 fs-2 pb-5">
									{t('home.span1.text111')}
								</div>
								<div className="col-12 text-center">
									<HashLink to="/services" className="btn btn-outline-light col-2">{t('buttons.mas')}</HashLink>
								</div>
							</div>
						</div>
					</div>
					<div className="carousel-item">
						<div className="slider sliderEcommerce d-flex justify-content-center align-items-center text-center">
							<div className="p-3 fs-3 col-lg-8 offset-lg-3" data-aos="fade-up" data-aos-duration="2000">
								<div className="col-12 col-lg-6 offset-lg-3">
									<p>{t('home.slider.text6')}</p>
									<p>{t('home.slider.text7')}</p>
								</div>
								<div className="col-12 text-center">
									<HashLink to="/services" className="btn btn-outline-light col-2">{t('buttons.mas')}</HashLink>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>

			<div className="container-fluid p-0 text-white">
				<div className="col-12 text-center display-5 mt-5 textBlue" data-aos="zoom-in-up">{t('home.span1.text9')}</div>
				<div className="container mt-5 textBlue" data-aos="zoom-in-up">{t('home.span1.text10')}</div>
				<div className="container mt-5 textBlue" data-aos="zoom-in-up">{t('home.span1.text101')}</div>
			</div>
			<div className="container-fluid clientsBg p-0 my-5 d-flex justify-content-center align-items-center">
				<div className="text-white text-start">
					<div className="col-12 col-lg-6 offset-lg-5 fs-3">
						<div className="col-12">
							{t('home.span1.text12')}
						</div>
						<div className="col-12">
							{t('home.span1.text13')}
						</div>
						<div className="col-12">
							{t('home.span1.text131')}
						</div>
					</div>
				</div>
			</div>
			
			<div className="container-fluid bgLightGrey textBlue">
				<div className="col-12 text-center display-5 py-5" data-aos="zoom-in">{t('home.span1.text24')}</div>
				<div className="row g-0">
					<div className="col-12 col-lg-4 p-3">
						<div className="rounded shadow-lg text-center p-5 SulLLave" data-aos="fade-right">
							<div className="col-12 display-3 textLightBlue">
								<i className="fas fa-brain"></i>
							</div>
							<div className="col-12 fs-4">
								{t('home.span1.text26')}
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-4 p-3">
						<div className="rounded shadow-lg text-center p-5 SulLLave" data-aos="fade-up">
							<div className="col-12 display-3 textLightBlue">
								<i className="far fa-gem"></i>
							</div>
							<div className="col-12 fs-4">
								{t('home.span1.text28')}
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-4 p-3">
						<div className="rounded shadow-lg text-center p-5 SulLLave" data-aos="fade-up">
							<div className="col-12 display-3 textLightBlue">
								<i className="far fa-handshake"></i>
							</div>
							<div className="col-12 fs-4">
								{t('home.span1.text30')}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid my-3" data-aos="fade-up">
				<div className="row g-0 rounded shadow bg-white d-flex justify-content-center align-items-center">
					<div className="col-12 col-lg-6 p-3 text-center" >
						<div className="">
							<div className="col-12 display-5">
								{t('home.span1.text15')}
							</div>
							<div className="col-12 display-5">
								{t('home.span1.text151')}
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-6 p-0 pt-2">
						<form onSubmit={contact} className="text-uppercase">
							<div className="row g-0">
								<div className="col-12 p-2">
									<input className="col-12 rounded shadow" name="fullname" id="fullname" type="text" placeholder={t('contact.form.text1')} aria-label="fullname" value={toSend.fullname} onChange={handleChange} required />
								</div>
								<div className="col-12 p-2">
									<input className="col-12 rounded shadow" name="phone" id="phone" type="tel" placeholder={t('contact.form.text2')} aria-label="phone" value={toSend.phone} onChange={handleChange} required />
								</div>
								<div className="col-12 p-2">
									<input className="col-12 rounded shadow" name="email" id="emailAddress" type="mail" placeholder={t('contact.form.text3')} aria-label="emailAddress" value={toSend.email} onChange={handleChange} required />
								</div>
								<div className="col-12 p-2">
									<textarea className="col-12 rounded shadow" id="message" name="message" rows="5" placeholder={t('contact.form.text4')} value={toSend.message} onChange={handleChange}></textarea>
								</div>
								<div className="col-12 text-center py-5">
									<button className="btnBlue btn" type="submit">{t('buttons.submit')} <i className="bi bi-arrow-right"></i></button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* <div className="col-12 text-center display-5 bgLightGrey pt-5">
				{t('home.span1.text34')}
			</div>
			<div className="row g-0 bgLightGrey">
				{store.post.map((item, index) => {
					if (index < 6) {
						return (
							<div className="col-12 col-lg-4 p-0" key={index} data-aos="fade-up">
								<Post
									title={item.title.rendered}
									yoast_head_json={item.yoast_head_json.og_description}
									link={item.link}
									url={item.featured_image_url}
									date={item.modified_gmt}
									//autor={item.author_meta.display_name}
									categories={item.categories.category_name}
								/>
							</div>
						);
					}
				})}
			</div> */}
		</div>
	);
};
